<template>
  <div id="capa">
    <center> 
     <center> <br v-if="isMobile"><br>
      <h1 style="color:#ffffff; margin-top:-75px;"><b>Bienvenidos</b></h1>
     </center>
     <b-row class="justify-content-center" >
      <b-col cols="12" md="5" lg="12" xl="12">
        <img src="elementos/jonrronero.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:28%; margin-top:-60px;'">
      </b-col>
     </b-row>
     <br><br>  <br v-if="!isMobile">
     <p v-if="perfil == 0" :style="isMobile ? 'font-size:16px; color:#ffffff; margin-top:-21px;' : 'font-size:22px; color:#ffffff; margin-top:-20px;'">
        Selecciona tu canal para comenzar</p>
      <p v-if="perfil == 3  || perfil == 1 || perfil == 8" :style="isMobile ? 'font-size:16px; color:#ffffff;  margin-top:-21px;' : 'font-size:22px; color:#ffffff;  margin-top:-21px;'">Ahora selecciona tu Perfil</p><br>
       <p v-if="perfil == 4 || perfil == 5 || perfil == 2  || perfil == 6 || perfil == 7 || perfil == 9 || perfil == 10 " style="font-size:22px; color:#ffffff;  margin-top:-45px;">Ingresa los siguientes datos:</p>
       <b-row class="justify-content-center" :style="isMobile ? 'margin-top: -30px;' : 'margin-top: -30px;'">
         <b-col cols="11" md="6"   v-if="perfil == 0">
 
              <b-row class="justify-content-center" > 
                  <b-col cols="9" md="9" lg="4" xl="4">
                     <img src="elementos/propios/propios.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(3)"> 
                  </b-col>
                  <b-col cols="9" md="9" lg="4" xl="4">
                    <br v-if="isMobile">
                    <img src="elementos/especialista/especialista.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(8)" > 
                  </b-col> 
                  <b-col cols="9" md="9" lg="4" xl="4"> 
                    <br v-if="isMobile">
                    <img src="elementos/retails/retail.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(1)" > 
                  </b-col> 
              </b-row>
              <br>
         
         </b-col>
         <b-col cols="11" md="11" lg="6" xl="6"  v-if="perfil == 3"> 
              <b-row class="justify-content-center" > 
                  <b-col cols="9" md="2"  v-if="!isMobile"></b-col>
                  <b-col cols="9" md="4" >
                    <br v-if="isMobile">
                    <img src="elementos/propios/director.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(4)"> 
                  </b-col>
                  <b-col cols="9" md="4" >
                    <br v-if="isMobile">
                    <img src="elementos/propios/subdirector.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(4)"> 
                  </b-col>
                  <b-col cols="9" md="2" v-if="!isMobile"></b-col>
                  <b-col cols="9" md="4" :class="isMobile ? 'mt-5' :'mt-4'"> 
                    <img src="elementos/propios/coordinador.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(4)"> 
                  </b-col>
                  <b-col cols="9" md="4" :class="isMobile ? 'mt-5' :'mt-4'"> 
                    <img src="elementos/propios/gerente.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(5)"> 
                  </b-col>
                  <b-col cols="9" md="4" :class="isMobile ? 'mt-5' :'mt-4'"> 
                    <img src="elementos/propios/ejecutivo.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(5)"> 
                  </b-col> 
              </b-row> 
         </b-col>
         <b-col cols="11" md="11" lg="6" xl="6"  v-if="perfil == 1 "> 
              <b-row class="justify-content-center" >
                  <b-col cols="9" md="4" >
                    <br v-if="isMobile">
                    <img src="elementos/propios/director.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(6)"> 
                  </b-col>
                  <b-col cols="9" md="4" :class="isMobile ? 'mt-5' :''"> 
                    <img src="elementos/propios/subdirector.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(6)"> 
                  </b-col> 
                  <b-col cols="9" md="4" :class="isMobile ? 'mt-5' :''">
                    <img src="elementos/retails/supervisor.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(6)"> 
                  </b-col> 
              </b-row> 
         </b-col>
        <b-col cols="11" md="11" lg="6" xl="6"  v-if="perfil == 8 "> 
              <b-row class="justify-content-center" >
                 <b-col cols="9" md="4" >
                    <br v-if="isMobile">
                    <img src="elementos/propios/director.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(9)"> 
                  </b-col>
                  <b-col cols="9" md="4" :class="isMobile ? 'mt-5' :''"> 
                    <img src="elementos/propios/subdirector.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="seleccionarCanal(9)"> 
                  </b-col> 
                  <b-col cols="9" md="4" :class="isMobile ? 'mt-5' :''">
                    <img src="elementos/especialista/lider.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:65%; cursor:pointer;'" @click="seleccionarCanal(9)"> 
                  </b-col>
              </b-row> 
         </b-col>
         <b-form aria-autocomplete="false">
            <b-col cols="8" md="4" lg="2" xl="2"  v-if="perfil == 5">
              <br>
                <b-form-input v-model="idpdv" placeholder="IDPDV"   type="text"  class=" text-center" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0) '"/>
                <b-form-input v-model="mrt" placeholder="AXC/MRT"   type="text"  class=" text-center mt-4" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/> 
                <b-form-input v-model="email" placeholder="Email"   type="text"  class=" text-center mt-4" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/>       
            </b-col>
            <b-col cols="8" md="4" lg="2" xl="2"   v-if="perfil == 4">
              <br> 
                <b-form-input v-model="mrt" placeholder="AXC/MRT"   type="text"  class=" text-center" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/> 
                <b-form-input v-model="email" placeholder="Email"   type="text"  class=" text-center mt-4" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/>       
            </b-col>
            <b-col cols="8" md="4" lg="2" xl="2"   v-if="perfil == 6 ">
              <br>
                <b-form-input placeholder="Email" v-model="email"  type="text"  class=" text-center" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/>
                <b-form-input v-model="mrt" placeholder="MRT"   type="text"  class=" text-center mt-4" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/> 
            </b-col>
            <b-col cols="8" md="4" lg="2" xl="2"   v-if="perfil == 7">
              <br>
                <b-form-input placeholder="IDPDV" v-model="idpdv"  type="text"  class=" text-center" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/>
                <b-form-input v-model="mrt" placeholder="Contraseña"   type="text"  class=" text-center mt-4" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/>   
            </b-col>
            <b-col cols="8" md="4" lg="2" xl="2"   v-if="perfil == 2">
              <br>
                <b-form-input v-model="email" placeholder="Email"   type="text"  class=" text-center" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/>
                <b-form-input v-model="mrt" placeholder="CURP"   type="text"  class=" text-center mt-4" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/> 
            </b-col>
            <b-col cols="8" md="4" lg="2" xl="2"   v-if="perfil == 9 || perfil == 10">
              <br>
                <b-form-input placeholder="Email" v-model="email"  type="text"  class=" text-center" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/>
                <b-form-input v-model="mrt" placeholder="MRT"   type="text"  class=" text-center mt-4" :style="isMobile ? 'height: 10vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)' : 'height: 2.3vw; clip-path: polygon(96% 18%, 98% 86%, 5% 97%, 1% 0)'"/> 
            </b-col>
            <b-col cols="12" md="5" lg="6" xl="6"  >    
                <img  v-if="perfil == 4 || perfil == 5 || perfil == 2 ||  perfil == 6 || perfil == 7 || perfil == 9 || perfil == 10 "
                     src="elementos/propios/ingresa.png" class="rounded mt-4" alt="Image" :style="isMobile ? 'width:50%;' : 'width:20%; cursor:pointer;'"  @click="login()" > 
                <b-spinner v-if="loader" small></b-spinner>  
                <u v-if="perfil == 2 || perfil == 1 || perfil == 3 || perfil == 8" style="color:#0c273a;  cursor: pointer; font-size:18px; " @click="seleccionarCanal(0)">Seleccionar otro canal</u> 
                <br>
                <u v-if="perfil == 4 || perfil == 5" style="color:#0c273a;  cursor: pointer; font-size:18px;" @click="seleccionarCanal(3)">Seleccionar otro perfil</u>
                <u v-if=" perfil == 6 || perfil == 7" style="color:#0c273a;  cursor: pointer; font-size:18px;" @click="seleccionarCanal(1)">Seleccionar otro perfil</u>
                <u v-if=" perfil == 9 || perfil == 10" style="color:#0c273a;  cursor: pointer; font-size:18px;" @click="seleccionarCanal(8)">Seleccionar otro perfil</u>
                 <p class="text-danger mt-5" v-if="errors.includes( 'sesion' )"><b v-if="perfil == 5">IDPDV/AXC/MRT y/o email incorrectos.</b><b v-else>AXC/MRT y/o email incorrectos.</b></p>
                <!-- <div class="text-center" v-if="perfil != 0">
                    <p class="text-danger mt-5" v-if="errors.includes( 'sesion' )"><b>Password y/o email incorrectos.</b></p>
                    <a href="https://asistentemovistar.com.mx/registro" target="_blank">
                      <b-button  v-if="perfil == 5 && val == 1 "    variant="primary" class="mt-1" :style="isMobile ? 'width: 80%; border-radius: 10px;' : 'width: 18%; border-radius: 10px;'"> 
                      Regístrate</b-button>
                    </a>
                </div> -->
            </b-col>
         </b-form>
       </b-row>   
       <!-- <p class="text-white" v-if="perfil == 0">Si presentas problemas con tu ingreso; <u style="color:#0c273a; cursor: pointer;" @click="abrirContacto()">Contáctanos</u></p> -->
     </center>
     <br>

    <b-modal id="contactoAyuda" title=" " hide-footer hide-header size="lg" :hideHeaderClose="true" :cancelDisabled="true" body-bg-variant="dark">
        <div class="text-center text-white" >            
          <img src="elementos/logo.png" class="rounded" alt="Image" :style="isMobile ? 'width:95%;' : 'width:60%;'">
          <template v-if="!enviar">
            <p class="mt-4" style="font-size:16px;">Queremos resolver tu incidencia. Agrega los siguientes datos y describe tu problema.</p>
            <b-form  @submit="ayuda" aria-autocomplete="false">
              <b-row class="justify-content-center mt-4" >
                <b-col cols="11">
                  <select class="form-control text-center" v-model="canal">
                    <option value="0" selected>Seleccione el canal al que pertenece</option>
                    <option value="1">Retail</option>
                    <option value="2">TLMKT</option>
                    <option value="3">Propio</option>
                  </select>
                    <div class="text-center mt-2">
                      <p class="text-danger" v-if="errors.includes( 'canal' )"><b>Seleccione canal.</b></p>
                    </div>
                </b-col>     
                <b-col cols="11">
                  <b-form-input v-model="correo" placeholder="Email"   type="text"  class=" mt-2 text-center" />
                    <div class="text-center mt-2">
                      <p class="text-danger" v-if="errors.includes( 'email' )"><b>Ingrese email.</b></p>
                    </div>
                </b-col>  
                <b-col cols="11">
                  <b-form-textarea
                      id="textarea"
                      v-model="descripcion"
                      placeholder="Cuéntanos qué es lo que sucede. Recuerda agregar tus datos de ingreso completos"
                      rows="4"
                      max-rows="6"
                      class=" mt-2 text-center"
                  ></b-form-textarea>
                  <div class="text-center mt-2">
                    <p class="text-danger" v-if="errors.includes( 'descripcion' )"><b>Ingresa descripción.</b></p>
                  </div>
                </b-col>  
                <b-col cols="8">
                    <v-btn  color="#0c273a"  class="mt-4" block dark style="text-transform:capitalize;" type="submit">
                      Enviar <b-spinner v-if="loader" small></b-spinner> 
                    </v-btn>
                    <br>
                    <p><u style="cursor: pointer; font-size:19px;" @click="cerrarModal()">Cerrar</u></p>
                </b-col>
              </b-row>
            </b-form>
          </template>
          <template v-else>
              <b-row class="justify-content-center">
                <b-col cols="10" md="9">
                  <br>
                  <b-card  style="background-color:rgba(0, 0, 0, 0);">
                  <br v-if="!isMobile">
                  <b-card-text class="text-center">
                    <center>
                      <img src="elementos/check.png" class="rounded" alt="Image" :style="isMobile ? 'width:30%;' : 'width:20%;'">
                    </center>
                      <p class="text-white mt-3" :style="isMobile ? 'font-size:37px;' : 'font-size:40px;'">
                            <b style="color:#0c273a;">Listo</b> <br>
                            
                      </p>  
                      <span :style="isMobile ? 'font-size:15px; color:#fff;' : 'font-size:22px; color:#fff;'">Tu mensaje se ha enviado exitosamente</span><br><br>  
                      <v-btn  color="#0c273a"  class="mt-4"  dark style="text-transform:capitalize; width: 30%;" @click="cerrarModal()">
                        OK
                      </v-btn>
                  </b-card-text>
                  <br v-if="!isMobile"> 
                  </b-card>
                </b-col>
              </b-row>
          </template>
        </div>
    </b-modal>
  </div>
</template>

<script>
  // import Multiselect from 'vue-multiselect'
// import $ from "jquery";
// import InitController from "@/utils/InitController";
import $ from "jquery";
export default {
  name: 'Inicio',
  //  components: { Multiselect },
  data(){
    return{
      isMobile: this.$mobile(),
      perfil:0,
      idpdv:"",
      email:"",
      mrt:"",
      errors:[],
      loader:false,
      agencia:0,
      cadena:0,
      arrayAgencia:[],
      arrayCadena:[],
      arrayPdv:[],
      canal:0,
      correo:"",
      descripcion:"",
      options: [
          { value: 0, text: 'Seleccione el canal al que pertenece' },
          { value: 1, text: 'Retail' },
          { value: 2, text: 'TLMKT' },
          { value: 3, text: 'Propio' },
        ],
      enviar:false,
      val:0,
      vertical:0
    }
  },
  computed:{
      listadoCadena(){

        if (this.cadena == 0) {          
            return this.arrayPdv;
        }else{
               return   this.arrayPdv.filter(item => {
                    return  item.agencia?.toLowerCase().includes(this.agencia.toLowerCase()) &&
                            item.cadena?.toLowerCase().includes(this.cadena.toLowerCase()) 
                    ;
              });
        }
      }
  },
  methods:{
    seleccionarCanal(id){
       this.perfil = id;
      this.idpdv = "";
      this.mrt = "";
      this.agencia = 0;
      this.cadena = 0;
      this.errors = [];
      this.email = "";
      this.val = 0;
    },
    apibitacora(){ 
      var perfil = "";
      var canal = "";
      switch (this.perfil) {
        case 5: 
          canal = 'Propio';
          perfil = 'Gerente / Ejecutivo';
        break; 
        case 4: 
          canal = 'Propio';
          perfil = 'Coordinador';
        break; 
        case 6: 
          canal = 'Retail';
          perfil = 'Líder / Supervisor';
        break;
        case 7: 
          canal = 'Retail';
          perfil = 'Promotor';
        break;
        case 9: 
           perfil = 'Lider';
          canal = 'Especialista';
        break;
        case 10:
          perfil = 'KAMs';
          canal = 'Especialista';
        break;
        case 2:
          perfil = 'TLMKT';
          canal = 'TLMKT';
        break; 
      }
     this.$api.post('auth/bitacora', {
          'correo' : this.email,
          'mrt' : this.mrt,
          'canal' : canal,
          'perfil' : perfil,
          'region' : 'N/A',
          'url' : '/',
          'seccion' : 'Login',
          'accion' : 'Inicio sesión',
          'detalle' : 'Accedio a la convención',
        }).then(
          response => {
             console.log(response);
          })
    },
    login() { 
        this.errors = []; 
        let model = this.prepareModel()

        this.loader = true;
        let request = this.$api.post("auth/login", model);
        request.then(
            response => {   
                console.log(response)      
                this.loader = false;  
                
                    if (response.data.sesion == 0)
                    {
                        this.val = response.data.val;
                        this.mrt = "";
                        this.errors.push('sesion');       
                    }else{ 
                        this.$store.dispatch("setLogin", response.data); 
                        this.$bus.$emit("fondo_capa", "fondoCapa")
                        this.$router.push("/");  
                        // InitController.startMetrics(response.data);
                        this.apibitacora();
                        
                    }
                
            },
            error => {
                this.loader = false;
                console.log(error);
            }
        )
            
    },
    prepareModel() {
        let model = new FormData();
        model.append("idpdv", this.idpdv)
        model.append("email", this.email)
        model.append("mrt", this.mrt)
        model.append("cadena", this.cadena)
        model.append("agencia", this.agencia)
        model.append("perfil", this.perfil)
        return model;
    },
    selectores(){
      var url= 'auth/selectores';
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.arrayAgencia = data.agencia;    
                this.arrayCadena = data.cadena;   
                this.arrayPdv = data.pdv;   
            }
        );
    },
    abrirContacto(){
      this.$bvModal.show('contactoAyuda');
    },
    cerrarModal(){
      this.correo = "";
      this.descripcion = "";
      this.canal = 0;
      this.errors = [];
      this.$bvModal.hide('contactoAyuda');
    },
    ayuda(e) {
       e.preventDefault();
        this.errors = [];
        let model = this.prepareModel2()
        
        if(this.canal == 0){
            this.errors.push('canal');     
        }else if (!this.correo){
            this.errors.push('email');       
        }else if(!this.descripcion){
            this.errors.push('descripcion');
        }else{
            this.loader = true;
            let request = this.$api.post("auth/ayuda", model);
            request.then(
                response => {   
                    console.log(response)      
                    this.loader = false;  
                    this.canal = 0;
                    this.correo = "";
                    this.descripcion = ""; 
                    this.enviar = true;          
                },
                error => {
                    this.loader = false;
                    console.log(error);
                }
            )
        }
    },
    prepareModel2() {
        let model = new FormData();
        model.append("canal", this.canal)
        model.append("correo", this.correo)
        model.append("descripcion", this.descripcion)
        return model;
    },
  },
  mounted(){
  var height  = $(window).height();
     console.log(height);
     
     if (height < 950 && !this.isMobile)
     {
        const elem2 = document.getElementById("capa");
         elem2.style.zoom='90%';  

        // this.$bus.$emit("ajusta_zoom")
     }  

     if (window.orientation == '90' && this.isMobile || window.orientation == '-90'  && this.isMobile )
     {
       this.vertical = 1;
     } 
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style> 